/* src/styles/BottomNav.css */
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; 
}

.nav-list {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  margin: 0;
  list-style: none;
}

.nav-item a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.nav-item.active a {
  color: #336be4; /* Active color */
}

.nav-item a span {
  font-size: 12px;
  margin-top: 5px;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.school-info {
  text-align: left;
  margin-left: 10px; /* Center align the text if needed */
}

.school-name, .school-address {
  display: block; /* Ensure each element takes a full line */
}

.main-content-ku {
  padding-bottom: 60px; /* Adjust based on the height of your bottom nav */
}